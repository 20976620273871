import React from "react";
import "../css/products.css";
import Header from "../components/header";
import Footer from "../components/footer";
import ProductBox from "../components/ProductBox";
import DiscordLogoImage from "../images/products/discord-logo.webp";
/* ------- PANELS ------- */
import Features from "../components/Features";
import FAQAccordion from "../components/FAQAccordion";
import ProductDetail from "../components/ProductDetail";

import { faqItems, productData, featureData } from "../strings/productPanels";

function ProductsPage() {
  return (
    <div>
      <Header />

      <section className="products">
        <div className="product-box">
          <div className="product-side">
            <i className="controler fa-solid fa-robot"></i>
            <span className="product-name-game">Discord Bot Hosting</span>
            <p className="product-title">Fast and reliable Bot hosting.</p>
            <p className="product-description">
              Discord bot hosting allows you to host your Discord bot 24/7. You
              have full access to the bot's files with access to our intuitive
              panel to control the bot.
            </p>

            {/* <a href="https://billing.bluefoxhost.com/index.php?rp=/store/discord-bot-hosting">
                            <button className="btn-product-box">
                                Order Now
                            </button>
                        </a> */}
          </div>

          <div className="product-side-right">
            <img
              className="product-image"
              src={DiscordLogoImage}
              alt="Product"
            />
          </div>
        </div>
        <div className="product-box-features">
          <h4
            className="section-heading"
            style={{ marginBottom: "0", textTransform: "uppercase" }}
          >
            Our basic features at no extra cost
          </h4>
          <p style={{ fontSize: "0.9rem" }} className="section-subheading">
            BlueFoxHost provides you with the ability to fully customize your
            hosting experience.
          </p>
          <Features features={featureData} />
        </div>
        <div className="product-box-second">
          <div className="products-boxes">
            <ProductBox
              type="bot"
              icon="robot"
              planName="BOT 512MB"
              gb="512MB"
              features={[
                "512MB DDR4",
                "1x vCPUs",
                "2GB NVMe Disk",
                "Unlimited Bandwidth",
                "1 MySQL Database",
              ]}
              price="$0.00"
              stock={false}
            />
            <ProductBox
              type="bot"
              icon="robot"
              planName="BOT 1GB"
              gb="1GB"
              features={[
                "1GB DDR4",
                "1x vCPUs",
                "5GB NVMe Disk",
                "Unlimited Bandwidth",
                "1 MySQL Database",
              ]}
              price="$0.00"
              stock={false}
            />
            <ProductBox
              type="bot"
              icon="robot"
              planName="BOT 2GB"
              gb="2GB"
              features={[
                "2GB DDR4",
                "2x vCPUs",
                "10GB NVMe Disk",
                "Unlimited Bandwidth",
                "1 MySQL Database",
              ]}
              price="$0.00"
              stock={false}
            />
          </div>
        </div>
        <div className="product-box-features hidden-mobile hidden-tablet">
          <div className="product-box-features-container hidden-mobile hidden-tablet">
            <ProductDetail {...productData} />
          </div>
        </div>
        <div className="hidden-large">
          <ProductDetail {...productData} />
        </div>
        <div className="product-box-features">
          <div className="product-box-features-container">
            <h1 className="product-title">FAQ</h1>
            <FAQAccordion faqItems={faqItems} />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ProductsPage;
