import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import ProductBox from "../components/ProductBox";
import TerrariaTreeImage from "../images/products/terraria-tree.webp";
/* ------- PANELS ------- */
import Features from "../components/Features";
import FAQAccordion from "../components/FAQAccordion";
import ProductDetail from "../components/ProductDetail";
import InfoBox from "../components/InfoBox";

import { faqItems, productData, featureData } from "../strings/productPanels";
const TerrariaHosting = () => {
  return (
    <div>
      <Header />

      <section className="products">
        <div className="product-box">
          <div className="product-side">
            <i className="controler fa-solid fa-gamepad"></i>
            <span className="product-name-game">Terraria Hosting</span>
            <p className="product-title terraria-text">
              Fast and reliable Terraria hosting.
            </p>
            <p className="product-description">
              Terraria is a 2D sandbox game with gameplay that revolves around
              exploration, building, crafting, combat, survival, and mining,
              playable in both single-player and multiplayer modes.
            </p>
            {/* <a href="https://billing.bluefoxhost.com/index.php?rp=/store/terraria-hosting">
                            <button className="btn-product-box terraria">
                                Order Now
                            </button>
                        </a> */}
          </div>
          <div className="product-side-right">
            <img
              className="product-image"
              src={TerrariaTreeImage}
              alt="Product"
            />
          </div>
        </div>
        <div className="product-box-features">
          <h4
            className="section-heading"
            style={{ marginBottom: "0", textTransform: "uppercase" }}
          >
            Our basic features at no extra cost
          </h4>
          <p style={{ fontSize: "0.9rem" }} className="section-subheading">
            BlueFoxHost provides you with the ability to fully customize your
            hosting experience.
          </p>
          <Features features={featureData} itemcss="te" />
        </div>
        <div className="products-boxes">
          <InfoBox
            message={[
              <p>
                Minecraft Hosting signups have been suspended. DO NOT REQUEST
                SERVERS. Learn more by clicking{" "}
                <a
                  style={{ textDecoration: "underline", color: "#2cb1f8" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://bluefoxhost.com/company/announcements/1/Suspension-of-New-Signups-for-Free-Tier-for-Game-Hosting"
                >
                  here
                </a>
                .
              </p>,
            ]}
            color="error"
            dismissible={false}
            icon="fa-triangle-exclamation"
          />
        </div>
        <div className="product-box-second">
          <div className="products-boxes">
            <ProductBox
              type="terraria"
              icon="gamepad"
              planName="TE-01"
              gb="1GB"
              features={[
                "1GB DDR4",
                "1x vCPUs",
                "10GB NVMe Disk",
                "Unlimited Bandwidth",
                "1 MySQL Database",
              ]}
              price="$0.00"
              stock={false}
            />
            <ProductBox
              type="terraria"
              icon="gamepad"
              planName="TE-02"
              gb="2GB"
              features={[
                "2GB DDR4",
                "1x vCPUs",
                "20GB NVMe Disk",
                "Unlimited Bandwidth",
                "1 MySQL Database",
              ]}
              price="$0.00"
              stock={false}
            />
            <ProductBox
              type="terraria"
              icon="gamepad"
              planName="TE-03"
              gb="3GB"
              features={[
                "3GB DDR4",
                "1x vCPUs",
                "30GB NVMe Disk",
                "Unlimited Bandwidth",
                "1 MySQL Database",
              ]}
              price="$0.00"
              stock={false}
            />
          </div>
        </div>
        <div className="product-box-features hidden-mobile hidden-tablet">
          <div className="product-box-features-container hidden-mobile hidden-tablet">
            <ProductDetail {...productData} featurecss="te" />
          </div>
        </div>
        <div className="hidden-large">
          <ProductDetail {...productData} featurecss="te" />
        </div>
        <div className="product-box-features">
          <div className="product-box-features-container">
            <h1 className="product-title">FAQ</h1>
            <FAQAccordion faqItems={faqItems} />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TerrariaHosting;
